import { makeStyles } from "@material-ui/core";

const useListHeaderStyles = makeStyles(theme => ({

  orderTitle: {
    marginBottom: theme.spacing(4)
  },

  orderTitleContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `0 ${theme.spacing(3)}px`,
    height: 85
  },

  listFilter: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },

  listFilterGroup: {
    display: "flex"
  },

  list: {
    marginTop: theme.spacing(2)
  }

}), {
  name: "editor-content"
});

export default useListHeaderStyles;