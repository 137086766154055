import * as React from "react";
import { List, Button, Avatar, Typography, Box } from "@material-ui/core";
import AdvancedListItem from "components/generic/advanced-list-item";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectOrders, setSelectedOrder } from "features/order-slice";
import { selectCustomers } from "features/customer-slice";
import { Order, OrderRow, Product } from "generated/client";
import theme from "theme/theme";
import strings from "localization/strings";
import DateUtils from "utils/date";
import Api from "api";
import { selectAuth } from "features/auth-slice";
import { ErrorContext } from "components/generic/error-handler";
import { setSelectedProduct } from "features/product-slice";
import { OrderData } from "types";

const ORDERS_PER_PAGE = 50;

/**
 * Application layout component
 */
const OrderList: React.FC = () => {
  const context = React.useContext(ErrorContext);
  const { accessToken } = useAppSelector(selectAuth);
  const orders = useAppSelector(selectOrders);
  const customers = useAppSelector(selectCustomers);
  const dispatch = useAppDispatch();

  const [ orderRowData, setOrderRowData ] = React.useState<Map<string, OrderData>>(new Map());
  const [ visibleOrders, setVisibleOrders ] = React.useState<Order[]>([]);
  const [ orderAmount, setOrderAmount ] = React.useState(ORDERS_PER_PAGE);

  /**
   * Effect for setting visible orders when order amount changes
   */
  React.useEffect(() => {
    if (orders.length < ORDERS_PER_PAGE) {
      setVisibleOrders(orders);
      return;
    }

    if (orders.length >= ORDERS_PER_PAGE) {
      setVisibleOrders(orders.slice(0, orderAmount));
    }
  }, [ orderAmount ]);

  /**
   * Fetches order rows
   *
   * @param order order
   */
  const fetchOrderRows = async (order: Order, callBack?: () => void) => {
    if (!accessToken || orderRowData.get(order.id)) {
      return;
    }

    try {
      const orderRowList = await Api.getOrderRowsApi(accessToken).listOrderRows({ orderId: order.id });
      const products = await Api.getProductsApi(accessToken).listProducts({ orderId: order.id });
      setOrderRowData(prev => new Map(prev).set(order.id, { orderRows: orderRowList, products: products }));
    } catch (error) {
      context.setError(strings.errorHandling.orderRows.list, error);
    }

    callBack && callBack();
  };

  /**
   * Event handler for order row click
   *
   * @param orderRow clicked order row
   * @param product product of clicked order row
   */
  const onOrderRowClick = (orderRow: OrderRow, product?: Product) => {
    const foundOrder = orders.find(order => order.id === orderRow.orderId);

    if (!foundOrder || !product) {
      return;
    }

    dispatch(setSelectedProduct(product));
    dispatch(setSelectedOrder(foundOrder));
  };

  /**
   * Renders single order row
   *
   * @param orderRow order row
   */
  const renderOrderRow = (orderRow: OrderRow) => {
    const singleOrderRowData = orderRowData.get(orderRow.orderId);

    if (!singleOrderRowData) {
      return null;
    }

    const orderRowProduct = singleOrderRowData.products.find(product => product.id === orderRow.productId);

    return (
      <AdvancedListItem
        title={ orderRowProduct?.name }
        subtitle={ orderRowProduct?.code }
        onClick={ () => onOrderRowClick(orderRow, orderRowProduct) }
        additionalContent={
          <Box mt={ 1 } display="flex">
            <Typography style={{ marginRight: theme.spacing(1) }}>
              { `${strings.generic.amount}: ${orderRow.deliveredAmount}` }
            </Typography>
            <Typography>
              { `${strings.generic.readyAmount}: ${orderRow.deliveredAmount}` }
            </Typography>
          </Box>
        }
      />
    );
  };

  /**
   * Render single order
   *
   * @param order order
   */
  const renderSingleOrder = (order: Order) => {
    const orderCustomer = customers.find(customer => order.customerId === customer.id);

    if (!orderCustomer) {
      return null;
    }

    const orderText = `${strings.order.title}: ${order.lemonId} - ${DateUtils.getDisplayDate(order.deliveryDate)}`;
    const rows = orderRowData.get(order.id)?.orderRows;

    return (
      <AdvancedListItem
        key={ order.id }
        title={ orderCustomer.name || "" }
        onClick={ callBack => fetchOrderRows(order, callBack) }
        checkForChildren
        avatar={
          <Avatar style={{ marginRight: theme.spacing(1) }}>
            <Typography>{ orderCustomer.classification }</Typography>
          </Avatar>
        }
        additionalContent={
          <Box mt={ 1 }>
            <Typography>{ orderText }</Typography>
          </Box>
        }
      >
        <List disablePadding>
          { rows && rows.map(renderOrderRow) }
        </List>
      </AdvancedListItem>
    );
  };

  /**
   * Component render
   */
  return (
    <>
      <List>
        { visibleOrders.map(order => renderSingleOrder(order)) }
        <Button
          onClick={ () => setOrderAmount(orderAmount + ORDERS_PER_PAGE) }
          fullWidth
          color="secondary"
        >
          { strings.generic.loadMore }
        </Button>
      </List>
    </>
  );
};

export default OrderList;