import { Box, CircularProgress, Fade } from "@material-ui/core";
import Api from "api";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { ErrorContext } from "components/generic/error-handler";
import { selectAuth } from "features/auth-slice";
import { setCustomers } from "features/customer-slice";
import { setOrders } from "features/order-slice";
import strings from "localization/strings";
import * as React from "react";
import useStoreInitializerStyles from "styles/containers/store-initializer";

/**
 * Functional component for initializing store
 *
 * @param props component properties
 */
const StoreInitializer: React.FC = ({ children }) => {
  const [ loading, setLoading ] = React.useState(true);
  const context = React.useContext(ErrorContext);
  const classes = useStoreInitializerStyles();

  const { accessToken } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  /**
   * Loads initial data
   */
  const loadInitialData = async () => {
    if (!accessToken) {
      context.setError(strings.errorHandling.title);
      return;
    }

    try {
      const [ customers, orders ] = await Promise.all([
        Api.getCustomersApi(accessToken).listCustomers(),
        Api.getOrdersApi(accessToken).listOrders({ })
      ]);

      dispatch(setOrders(orders));
      dispatch(setCustomers(customers));
    } catch (error) {
      context.setError(strings.errorHandling.store, error);
    }

    setLoading(false);
  };

  /**
   * Effect for loading initial data
   */
  React.useEffect(() => {
    loadInitialData();
  }, []);

  if (loading) {
    return (
      <Fade
        appear={ false }
        in={ loading }
      >
        <Box className={ classes.loaderContainer }>
          <CircularProgress size={ 100 } color="secondary"/>
        </Box>
      </Fade>
    );
  }

  return <>{ children }</>;
};

export default StoreInitializer;