/* tslint:disable */
/* eslint-disable */
/**
 * Casemet API spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Material,
    MaterialFromJSON,
    MaterialToJSON,
} from '../models';

export interface FindMaterialRequest {
    materialId: string;
}

export interface ListMaterialsRequest {
    productId?: string;
    hasRiskOnly?: boolean;
}

/**
 * 
 */
export class MaterialsApi extends runtime.BaseAPI {

    /**
     * Finds material details by material id
     * Finds a material
     */
    async findMaterialRaw(requestParameters: FindMaterialRequest): Promise<runtime.ApiResponse<Material>> {
        if (requestParameters.materialId === null || requestParameters.materialId === undefined) {
            throw new runtime.RequiredError('materialId','Required parameter requestParameters.materialId was null or undefined when calling findMaterial.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/materials/{materialId}`.replace(`{${"materialId"}}`, encodeURIComponent(String(requestParameters.materialId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MaterialFromJSON(jsonValue));
    }

    /**
     * Finds material details by material id
     * Finds a material
     */
    async findMaterial(requestParameters: FindMaterialRequest): Promise<Material> {
        const response = await this.findMaterialRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists materials
     * Lists materials
     */
    async listMaterialsRaw(requestParameters: ListMaterialsRequest): Promise<runtime.ApiResponse<Array<Material>>> {
        const queryParameters: any = {};

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        if (requestParameters.hasRiskOnly !== undefined) {
            queryParameters['hasRiskOnly'] = requestParameters.hasRiskOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/materials`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MaterialFromJSON));
    }

    /**
     * Lists materials
     * Lists materials
     */
    async listMaterials(requestParameters: ListMaterialsRequest): Promise<Array<Material>> {
        const response = await this.listMaterialsRaw(requestParameters);
        return await response.value();
    }

}
