import { makeStyles } from "@material-ui/core";

const useHorizontalDividerStyles = makeStyles(theme => ({

  root: {
    height: 4,
    backgroundColor: theme.palette.primary.main
  }

}), {
  name: "horizontal-divider"
});

export default useHorizontalDividerStyles;