import Config from "app/config";
import { AccessToken } from "types";
import { Configuration, CustomersApi, MaterialsApi, OrderRowsApi, OrdersApi, ProductsApi, WorkItemsApi } from "../generated/client";

/**
 * Utility class for loading api with predefined configuration
 */
export default class Api {

  /**
   * Gets initialized customers API
   *
   * @param accessToken access token
   * @returns initialized customers API
   */
  public static getCustomersApi = (accessToken: AccessToken) => {
    return new CustomersApi(Api.getConfiguration(accessToken));
  };

  /**
   * Gets initialized materials API
   *
   * @param accessToken access token
   * @returns initialized materials API
   */
  public static getMaterialsApi = (accessToken: AccessToken) => {
    return new MaterialsApi(Api.getConfiguration(accessToken));
  };

  /**
   * Gets initialized orders API
   *
   * @param accessToken access token
   * @returns initialized orders API
   */
  public static getOrdersApi = (accessToken: AccessToken) => {
    return new OrdersApi(Api.getConfiguration(accessToken));
  };

  /**
   * Gets initialized order rows API
   *
   * @param accessToken access token
   * @returns initialized order rows API
   */
  public static getOrderRowsApi = (accessToken: AccessToken) => {
    return new OrderRowsApi(Api.getConfiguration(accessToken));
  };

  /**
   * Gets initialized products API
   *
   * @param accessToken access token
   * @returns initialized products API
   */
  public static getProductsApi = (accessToken: AccessToken) => {
    return new ProductsApi(Api.getConfiguration(accessToken));
  };

  /**
   * Gets initialized work items API
   *
   * @param accessToken access token
   * @returns initialized work items API
   */
  public static getWorkItemsApi = (accessToken: AccessToken) => {
    return new WorkItemsApi(Api.getConfiguration(accessToken));
  };

  /**
   * Gets api configuration
   *
   * @param accessToken access token
   * @returns new configuration
   */
  private static getConfiguration = ({ access_token }: AccessToken) => {
    if (!access_token) {
      throw Error("Get configuration failed - no access token");
    }

    return new Configuration({
      basePath: Config.get().api.baseUrl,
      accessToken: access_token
    });
  };

}