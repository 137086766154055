import { makeStyles } from "@material-ui/core";

const drawerWidth = 400;

const useEditorLayoutStyles = makeStyles(theme => ({

  root: {
    backgroundColor: theme.palette.background.default,
    height: "100vh",
    width: "100vw",
    display: "flex"
  },

  drawer: {
    width: drawerWidth,
    height: "100%"
  },

  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.background.default
  },

  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.dark
  },

  logoContainer: {
    width: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  logo: {
    width: "100%"
  }

}), {
  name: "editor-layout"
});

export default useEditorLayoutStyles;