/* tslint:disable */
/* eslint-disable */
/**
 * Casemet API spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * WorkItem object
 * @export
 * @interface WorkItem
 */
export interface WorkItem {
    /**
     * 
     * @type {string}
     * @memberof WorkItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof WorkItem
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof WorkItem
     */
    lemonId: string;
    /**
     * 
     * @type {string}
     * @memberof WorkItem
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WorkItem
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof WorkItem
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof WorkItem
     */
    endDate: Date;
    /**
     * 
     * @type {number}
     * @memberof WorkItem
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkItem
     */
    readyAmount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WorkItem
     */
    hasRisk: boolean;
    /**
     * 
     * @type {number}
     * @memberof WorkItem
     */
    daysLate?: number;
}

export function WorkItemFromJSON(json: any): WorkItem {
    return WorkItemFromJSONTyped(json, false);
}

export function WorkItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'productId': json['productId'],
        'lemonId': json['lemonId'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'endDate': (new Date(json['endDate'])),
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'readyAmount': !exists(json, 'readyAmount') ? undefined : json['readyAmount'],
        'hasRisk': json['hasRisk'],
        'daysLate': !exists(json, 'daysLate') ? undefined : json['daysLate'],
    };
}

export function WorkItemToJSON(value?: WorkItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'productId': value.productId,
        'lemonId': value.lemonId,
        'name': value.name,
        'description': value.description,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'endDate': (value.endDate.toISOString()),
        'amount': value.amount,
        'readyAmount': value.readyAmount,
        'hasRisk': value.hasRisk,
        'daysLate': value.daysLate,
    };
}


