/* tslint:disable */
/* eslint-disable */
/**
 * Casemet API spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Order object
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    lemonId: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    code: string;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    deliveryDate: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    hasRisk: boolean;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    daysLate?: number;
}

export function OrderFromJSON(json: any): Order {
    return OrderFromJSONTyped(json, false);
}

export function OrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): Order {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'lemonId': json['lemonId'],
        'customerId': json['customerId'],
        'code': json['code'],
        'deliveryDate': (new Date(json['deliveryDate'])),
        'hasRisk': json['hasRisk'],
        'daysLate': !exists(json, 'daysLate') ? undefined : json['daysLate'],
    };
}

export function OrderToJSON(value?: Order | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'lemonId': value.lemonId,
        'customerId': value.customerId,
        'code': value.code,
        'deliveryDate': (value.deliveryDate.toISOString()),
        'hasRisk': value.hasRisk,
        'daysLate': value.daysLate,
    };
}


