import { configureStore } from "@reduxjs/toolkit";
import authReducer from "features/auth-slice";
import localeReducer from "features/locale-slice";
import filterReducer from "features/filter-slice";
import orderReducer from "features/order-slice";
import customerReducer from "features/customer-slice";
import productReducer from "features/product-slice";

/**
 * Initialized Redux store
 */
export const store = configureStore({
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
  reducer: {
    auth: authReducer,
    locale: localeReducer,
    filter: filterReducer,
    order: orderReducer,
    customer: customerReducer,
    product: productReducer
  }
});

/**
 * Type of root state of Redux store
 */
export type RootState = ReturnType<typeof store.getState>;

/**
 * Type of dispatch method for dispatching actions to Redux store
 */
export type AppDispatch = typeof store.dispatch;