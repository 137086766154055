/* tslint:disable */
/* eslint-disable */
/**
 * Casemet API spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    OrderRow,
    OrderRowFromJSON,
    OrderRowToJSON,
} from '../models';

export interface FindOrderRowRequest {
    orderRowId: string;
}

export interface ListOrderRowsRequest {
    orderId?: string;
    productId?: string;
    hasRiskOnly?: boolean;
}

/**
 * 
 */
export class OrderRowsApi extends runtime.BaseAPI {

    /**
     * Finds order row by order row id
     * Finds order row
     */
    async findOrderRowRaw(requestParameters: FindOrderRowRequest): Promise<runtime.ApiResponse<OrderRow>> {
        if (requestParameters.orderRowId === null || requestParameters.orderRowId === undefined) {
            throw new runtime.RequiredError('orderRowId','Required parameter requestParameters.orderRowId was null or undefined when calling findOrderRow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orderRows/{orderRowId}`.replace(`{${"orderRowId"}}`, encodeURIComponent(String(requestParameters.orderRowId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderRowFromJSON(jsonValue));
    }

    /**
     * Finds order row by order row id
     * Finds order row
     */
    async findOrderRow(requestParameters: FindOrderRowRequest): Promise<OrderRow> {
        const response = await this.findOrderRowRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists order rows
     * Lists order rows
     */
    async listOrderRowsRaw(requestParameters: ListOrderRowsRequest): Promise<runtime.ApiResponse<Array<OrderRow>>> {
        const queryParameters: any = {};

        if (requestParameters.orderId !== undefined) {
            queryParameters['orderId'] = requestParameters.orderId;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        if (requestParameters.hasRiskOnly !== undefined) {
            queryParameters['hasRiskOnly'] = requestParameters.hasRiskOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orderRows`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderRowFromJSON));
    }

    /**
     * Lists order rows
     * Lists order rows
     */
    async listOrderRows(requestParameters: ListOrderRowsRequest): Promise<Array<OrderRow>> {
        const response = await this.listOrderRowsRaw(requestParameters);
        return await response.value();
    }

}
