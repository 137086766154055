import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "app/store";
import { Product } from "generated/client";

/**
 * Interface for customer state
 */
interface ProductsState {
  selectedProduct?: Product;
}

/**
 * Initial products state
 */
const initialState: ProductsState = {
};

/**
 * Products slice of Redux store
 */
export const productSlice = createSlice({
  name: "products",
  initialState: initialState,
  reducers: {
    setSelectedProduct: (state, { payload }: PayloadAction<Product>) => {
      state.selectedProduct = payload;
    }
  }
});

/**
 * Product actions from created product slice
 */
export const { setSelectedProduct } = productSlice.actions;

/**
 * Select selected product selector
 *
 * @param state Redux store root state
 * @returns selected product from Redux store
 */
export const selectSelectedProduct = (state: RootState) => state.product.selectedProduct;

/**
 * Reducer for product slice
 */
export default productSlice.reducer;