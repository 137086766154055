import * as React from "react";
import { useAppDispatch } from "app/hooks";
import { setLocale } from "features/locale-slice";
import strings from "localization/strings";
import MainScreen from "./screens/main/main-screen";
import AccessTokenRefresh from "./containers/access-token-refresh";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ErrorHandler from "./generic/error-handler";
import StoreInitializer from "./containers/store-initializer";

/**
 * App component
 */
const App: React.FC = () => {
  const dispatch = useAppDispatch();

  React.useLayoutEffect(() => {
    dispatch(setLocale(strings.getLanguage()));
    // eslint-disable-next-line
  }, []);

  return (
    // Add when Keycloak has been setup
    <AccessTokenRefresh>
      <ErrorHandler>
        <StoreInitializer>
          <Router>
            <Switch>
              <Route
                path="/"
                exact
                component={ MainScreen }
              />
            </Switch>
          </Router>
        </StoreInitializer>
      </ErrorHandler>
    </AccessTokenRefresh>
  );
};

export default App;