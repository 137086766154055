import * as React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { Box, Divider, MenuItem, TextField, Typography } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectFilter, setCustomerCategory, setEndDate, setShow, setSortBy, setStartDate } from "features/filter-slice";
import strings from "localization/strings";
import useOrderFilterStyles from "styles/order-filter/order-filter";
import { CustomerCategoryOpt, CustomerCategoryOpts, ShowOrderOpt, ShowOrderOpts, SortByOpt, SortByOpts } from "types";
import HorizontalDivider from "../generic/horizontal-divider";

/**
 * Filter component. Provides typing message animation
 */
const OrderFilter: React.FC = () => {
  const classes = useOrderFilterStyles();
  const dispatch = useAppDispatch();
  const {
    showOrder,
    startDate,
    endDate,
    customerCategory,
    sortBy
  } = useAppSelector(selectFilter);

  /**
   * Select show order handler
   */
  const handleShowOrderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setShow(event.target.value as ShowOrderOpt));
  };

  /**
   * Select customer categories handler
   */
  const handleCustomerCategoriesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setCustomerCategory(event.target.value as CustomerCategoryOpt));
  };

  /**
   * Select sort by handler
   */
  const handleSortByChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSortBy(event.target.value as SortByOpt));
  };

  /**
   * Renders filter title
   */
  const renderTitle = () => {
    return (
      <>
        <Box className={ classes.filterItemLeft }>
          <Typography variant="h3" component="h3">{ strings.filter.title }</Typography>
        </Box>
        <Box className={ classes.filterItemRight }>
          <TextField
            select
            label={ strings.filter.show.title }
            value={ showOrder }
            onChange={ handleShowOrderChange }
          >
            <MenuItem value={ ShowOrderOpts.ALL_ORDERS }>{ strings.filter.show.allOrders }</MenuItem>
            <MenuItem value={ ShowOrderOpts.RISKS }>{ strings.filter.show.risks }</MenuItem>
          </TextField>
        </Box>
      </>
    );
  };

  /**
   * Renders date filter
   */
  const renderDateFilter = () => {
    return (
      <MuiPickersUtilsProvider utils={ DateFnsUtils }>
        <Box className={ classes.filterItemLeft }>
          <KeyboardDatePicker
            disableToolbar
            className={ classes.datePicker }
            size="small"
            inputVariant="standard"
            InputProps={{ disableUnderline: true }}
            variant="inline"
            format="dd.MM.yyyy"
            margin="normal"
            label={ strings.filter.dateStart }
            value={ startDate }
            onChange={ (date: Date | null) => dispatch(setStartDate(date!)) }
          />
        </Box>
        <Divider orientation="vertical" className={ classes.verticalDivider }/>
        <Box className={ classes.filterItemRight }>
          <KeyboardDatePicker
            disableToolbar
            className={ classes.datePicker }
            inputVariant="standard"
            InputProps={{ disableUnderline: true }}
            variant="inline"
            format="dd.MM.yyyy"
            margin="normal"
            label={ strings.filter.dateEnd }
            value={ endDate }
            onChange={ (date: Date | null) => dispatch(setEndDate(date!)) }
          />
        </Box>
      </MuiPickersUtilsProvider>
    );
  };

  /**
   * Renders category filter
   */
  const renderCategoryFilter = () => {
    return (
      <>
        <Box className={ classes.filterItemLeft }>
          <TextField
            select
            label={ strings.filter.customerCategory.title }
            value={ customerCategory }
            onChange={ handleCustomerCategoriesChange }
          >
            <MenuItem value={ CustomerCategoryOpts.ALL_CATEGORIES }>
              { strings.filter.customerCategory.allCategory }
            </MenuItem>
          </TextField>
        </Box>
        <Box className={ classes.filterItemRight }>
          <TextField
            select
            label={ strings.filter.sortBy.title }
            value={ sortBy }
            onChange={ handleSortByChange }
          >
            <MenuItem value={ SortByOpts.LATEST }>{ strings.filter.sortBy.latest }</MenuItem>
          </TextField>
        </Box>
      </>
    );
  };

  return (
    <>
      <Box className={ classes.root }>
        <Box className={ classes.title }>
          { renderTitle() }
        </Box>
        <Box className={ classes.filterRow } borderTop={ 1 } borderColor="grey.300">
          { renderDateFilter() }
        </Box>
        <Box className={ classes.filterRow } borderTop={ 1 } borderColor="grey.300">
          { renderCategoryFilter() }
        </Box>
      </Box>
      <HorizontalDivider/>
    </>
  );
};

export default OrderFilter;