import * as React from "react";
import { useAppDispatch, useAppSelector, useInterval } from "app/hooks";
import { login, selectAuth } from "features/auth-slice";
import AuthUtils from "utils/auth";

/**
 * Component for keeping authentication token fresh
 *
 * @param props component properties
 */
const AccessTokenRefresh: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const { keycloak, accessToken } = useAppSelector(selectAuth);

  /**
   * Effect that initializes Keycloak
   */
  React.useEffect(() => {
    AuthUtils.initAuth()
      // eslint-disable-next-line @typescript-eslint/no-shadow
      .then(({ keycloak, accessToken }) => dispatch(login({ keycloak: keycloak, accessToken: accessToken })))
      // eslint-disable-next-line no-console
      .catch(e => console.error(e));
    // eslint-disable-next-line
  }, []);

  /**
   * Interval that refreshes access token if needed
   */
  useInterval(() => {
    AuthUtils.refreshAccessToken(keycloak)
      // eslint-disable-next-line @typescript-eslint/no-shadow
      .then(accessToken => accessToken && dispatch(login({ keycloak: keycloak, accessToken: accessToken })));
  }, 1000 * 60);

  /**
   * Component render
   */
  return accessToken ? <>{ children }</> : null;
};

export default AccessTokenRefresh;