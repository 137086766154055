/* tslint:disable */
/* eslint-disable */
/**
 * Casemet API spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Customer,
    CustomerFromJSON,
    CustomerToJSON,
} from '../models';

export interface FindCustomerRequest {
    customerId: string;
}

/**
 * 
 */
export class CustomersApi extends runtime.BaseAPI {

    /**
     * Finds customer details by customer id
     * Finds a customer
     */
    async findCustomerRaw(requestParameters: FindCustomerRequest): Promise<runtime.ApiResponse<Customer>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling findCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customers/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerFromJSON(jsonValue));
    }

    /**
     * Finds customer details by customer id
     * Finds a customer
     */
    async findCustomer(requestParameters: FindCustomerRequest): Promise<Customer> {
        const response = await this.findCustomerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists customers
     * Lists customers
     */
    async listCustomersRaw(): Promise<runtime.ApiResponse<Array<Customer>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomerFromJSON));
    }

    /**
     * Lists customers
     * Lists customers
     */
    async listCustomers(): Promise<Array<Customer>> {
        const response = await this.listCustomersRaw();
        return await response.value();
    }

}
