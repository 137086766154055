import React from "react";
import { Box, CircularProgress, Collapse, ListItem, Typography } from "@material-ui/core";
import useAdvancedListItemStyles from "styles/generic/advanced-list-item";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

/**
 * Component properties
 */
interface Props {
  title?: string;
  subtitle?: string;
  selected?: boolean;
  avatar?: React.ReactNode;
  additionalContent?: React.ReactNode;
  checkForChildren?: boolean;
  disableClick?: boolean;
  onClick?: (callback?: () => void) => void;
}

/**
 * Advanced list item component
 *
 * @param props component properties
 */
const AdvancedListItem: React.FC<Props> = ({
  title,
  subtitle,
  selected,
  avatar,
  additionalContent,
  checkForChildren,
  disableClick,
  onClick,
  children
}) => {
  const classes = useAdvancedListItemStyles();
  const [ open, setOpen ] = React.useState(false);
  const [ loading, setLoading ] = React.useState(false);
  const [ dataLoaded, setDataLoaded ] = React.useState(false);

  /**
   * Callback function for settings loading to false
   */
  const setLoadingCallback = () => {
    setLoading(false);
    setDataLoaded(true);
  };

  /**
   * Event handler for toggle collapse
   *
   * @param event React mouse event
   */
  const onToggleCollapse = (event: React.MouseEvent) => {
    event.stopPropagation();
    !dataLoaded && setLoading(true);
    !open && onClick && onClick(setLoadingCallback);
    setOpen(!open);
  };

  /**
   * Renders collapse button
   */
  const renderCollapseButton = () => {
    return open ? <ExpandLess/> : <ExpandMore/>;
  };

  /**
   * Renders children
   */
  const renderChildren = () => {
    if (checkForChildren && !dataLoaded && loading) {
      return <CircularProgress size={ 50 } color="secondary"/>;
    }

    return !!children &&
      <Collapse
        in={ open }
        timeout="auto"
        unmountOnExit
      >
        <Box pl={ 2 }>
          { children }
        </Box>
      </Collapse>;
  };

  /**
   * Renders additional content
   */
  const renderAdditionalContent = () => (
    <>
      { !!children && renderCollapseButton() }
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center">
          { avatar }
          <Box display="flex" flexDirection="column">
            { title &&
              <Typography variant="h5">
                { title }
              </Typography>
            }
            { subtitle &&
              <Typography variant="h6">
                { subtitle }
              </Typography>
            }
          </Box>
        </Box>
        { additionalContent }
      </Box>
    </>
  );

  if (disableClick) {
    return (
      <>
        <ListItem
          className={ (checkForChildren && open) ? classes.openListItem : classes.listItem }
          onClick={ onToggleCollapse }
          selected={ selected }
        >
          { renderAdditionalContent() }
        </ListItem>
        { renderChildren() }
      </>
    );
  }

  /**
   * Component render
   */
  return (
    <>
      <ListItem
        button
        className={ (checkForChildren && open) ? classes.openListItem : classes.listItem }
        onClick={ onToggleCollapse }
        disableRipple
        selected={ selected }
      >
        { renderAdditionalContent() }
      </ListItem>
      { renderChildren() }
    </>
  );
};

export default AdvancedListItem;