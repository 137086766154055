/* tslint:disable */
/* eslint-disable */
/**
 * Casemet API spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    WorkItem,
    WorkItemFromJSON,
    WorkItemToJSON,
} from '../models';

export interface FindWorkItemRequest {
    workitemId: string;
}

export interface ListWorkItemsRequest {
    productId?: string;
    orderId?: string;
    hasRiskOnly?: boolean;
    incompleteOnly?: boolean;
    sortBy?: ListWorkItemsSortByEnum;
}

/**
 * 
 */
export class WorkItemsApi extends runtime.BaseAPI {

    /**
     * Finds workitem details by workitem id
     * Finds a workitem
     */
    async findWorkItemRaw(requestParameters: FindWorkItemRequest): Promise<runtime.ApiResponse<WorkItem>> {
        if (requestParameters.workitemId === null || requestParameters.workitemId === undefined) {
            throw new runtime.RequiredError('workitemId','Required parameter requestParameters.workitemId was null or undefined when calling findWorkItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/workitems/{workitemId}`.replace(`{${"workitemId"}}`, encodeURIComponent(String(requestParameters.workitemId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkItemFromJSON(jsonValue));
    }

    /**
     * Finds workitem details by workitem id
     * Finds a workitem
     */
    async findWorkItem(requestParameters: FindWorkItemRequest): Promise<WorkItem> {
        const response = await this.findWorkItemRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists workitems
     * Lists workitems
     */
    async listWorkItemsRaw(requestParameters: ListWorkItemsRequest): Promise<runtime.ApiResponse<Array<WorkItem>>> {
        const queryParameters: any = {};

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        if (requestParameters.orderId !== undefined) {
            queryParameters['orderId'] = requestParameters.orderId;
        }

        if (requestParameters.hasRiskOnly !== undefined) {
            queryParameters['hasRiskOnly'] = requestParameters.hasRiskOnly;
        }

        if (requestParameters.incompleteOnly !== undefined) {
            queryParameters['incompleteOnly'] = requestParameters.incompleteOnly;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/workitems`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WorkItemFromJSON));
    }

    /**
     * Lists workitems
     * Lists workitems
     */
    async listWorkItems(requestParameters: ListWorkItemsRequest): Promise<Array<WorkItem>> {
        const response = await this.listWorkItemsRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ListWorkItemsSortByEnum {
    DaysLate = 'DAYS_LATE',
    Name = 'NAME'
}
