/* tslint:disable */
/* eslint-disable */
/**
 * Casemet API spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Order,
    OrderFromJSON,
    OrderToJSON,
} from '../models';

export interface FindOrderRequest {
    orderId: string;
}

export interface ListOrdersRequest {
    deliveryBefore?: Date;
    deliveryAfter?: Date;
    customerClassification?: ListOrdersCustomerClassificationEnum;
    hasRiskOnly?: boolean;
    sortBy?: ListOrdersSortByEnum;
}

/**
 * 
 */
export class OrdersApi extends runtime.BaseAPI {

    /**
     * Finds order details by order id
     * Finds a order
     */
    async findOrderRaw(requestParameters: FindOrderRequest): Promise<runtime.ApiResponse<Order>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling findOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders/{orderId}`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderFromJSON(jsonValue));
    }

    /**
     * Finds order details by order id
     * Finds a order
     */
    async findOrder(requestParameters: FindOrderRequest): Promise<Order> {
        const response = await this.findOrderRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists orders
     * Lists orders
     */
    async listOrdersRaw(requestParameters: ListOrdersRequest): Promise<runtime.ApiResponse<Array<Order>>> {
        const queryParameters: any = {};

        if (requestParameters.deliveryBefore !== undefined) {
            queryParameters['deliveryBefore'] = (requestParameters.deliveryBefore as any).toISOString();
        }

        if (requestParameters.deliveryAfter !== undefined) {
            queryParameters['deliveryAfter'] = (requestParameters.deliveryAfter as any).toISOString();
        }

        if (requestParameters.customerClassification !== undefined) {
            queryParameters['customerClassification'] = requestParameters.customerClassification;
        }

        if (requestParameters.hasRiskOnly !== undefined) {
            queryParameters['hasRiskOnly'] = requestParameters.hasRiskOnly;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderFromJSON));
    }

    /**
     * Lists orders
     * Lists orders
     */
    async listOrders(requestParameters: ListOrdersRequest): Promise<Array<Order>> {
        const response = await this.listOrdersRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ListOrdersCustomerClassificationEnum {
    A = 'A',
    B = 'B',
    C = 'C'
}
/**
    * @export
    * @enum {string}
    */
export enum ListOrdersSortByEnum {
    DaysLate = 'DAYS_LATE',
    DeliveryDate = 'DELIVERY_DATE'
}
