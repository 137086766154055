import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "app/store";
import { CustomerCategoryOpt, CustomerCategoryOpts, ShowOrderOpt, ShowOrderOpts, SortByOpt } from "types";

/**
 * Interface describing filter state in Redux
 */
export interface FilterState {
  showOrder: ShowOrderOpt;
  startDate?: Date;
  endDate?: Date;
  customerCategory: CustomerCategoryOpt;
  sortBy?: SortByOpt;
}

/**
 * Initial filter state
 */
const initialState: FilterState = {
  showOrder: ShowOrderOpts.ALL_ORDERS,
  startDate: undefined,
  endDate: undefined,
  customerCategory: CustomerCategoryOpts.ALL_CATEGORIES,
  sortBy: undefined
};

/**
 * Filter slice of Redux store
 */
export const filterSlice = createSlice({
  name: "filter",
  initialState: initialState,
  reducers: {
    setShow: (state, action: PayloadAction<ShowOrderOpt>) => {
      state.showOrder = action.payload;
    },
    setStartDate: (state, action: PayloadAction<Date>) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action: PayloadAction<Date>) => {
      state.endDate = action.payload;
    },
    setCustomerCategory: (state, action: PayloadAction<CustomerCategoryOpt>) => {
      state.customerCategory = action.payload;
    },
    setSortBy: (state, action: PayloadAction<SortByOpt>) => {
      state.sortBy = action.payload;
    }
  }
});

/**
 * Filter actions from created auth slice
 */
export const { setShow, setStartDate, setEndDate, setCustomerCategory, setSortBy } = filterSlice.actions;

/**
 * Select Keycloak selector, used with useAppSelector custom hook defined for Redux store
 *
 * @param state Redux store root state
 * @returns keycloak instance from Redux store
 */
export const selectFilter = (state: RootState) => state.filter;

/**
 * Reducer for auth slice
 */
export default filterSlice.reducer;