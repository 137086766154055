import { makeStyles } from "@material-ui/core";

const useAdvancedListItemStyles = makeStyles(theme => ({

  root: {
    "&.no-children": {

    },
    "&.child": {
      "& .MuiAccordionSummary-root": {
        borderBottom: "none"
      }
    },
    "&.Mui-expanded.colored": {
      backgroundColor: "rgba(188,207,0,0.1)"
    }
  },

  accordionChild: {
    width: "100%",
    "&.child-offset": {
      width: "100%",
      marginLeft: theme.spacing(2)
    }
  },

  listItem: {
    width: "100%",
    padding: "16px 0",
    paddingLeft: 50,
    "&.disable-offset": {
      padding: "16px 0",
      paddingLeft: 0
    }
  },

  openListItem: {
    width: "100%",
    padding: "16px 0",
    paddingLeft: 50,
    "&.disable-offset": {
      padding: "16px 0",
      paddingLeft: 0
    },
    backgroundColor: "rgba(188,207,0,0.5)",
    "&.MuiListItem-button:hover": {
      backgroundColor: "rgba(188,207,0,0.5)"
    }
  }

}), {
  name: "advanced-list-item"
});

export default useAdvancedListItemStyles;