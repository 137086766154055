import { Box, Paper, Typography } from "@material-ui/core";
import * as React from "react";
import useListHeaderStyles from "styles/editor-content/list-components/header";
import theme from "theme/theme";
import DateUtils from "utils/date";

/**
 * Interface for component properties
 */
interface Props {
  orderTitle: string;
  customerName: string;
  avatar: string;
  progress: string;
  date?: Date;
}

/**
 * Order title component
 *
 * @param props props
 */
const OrderTitle: React.FC<Props> = ({
  orderTitle,
  customerName,
  avatar,
  progress,
  date
}) => {
  const classes = useListHeaderStyles();

  return (
    <Box className={ classes.orderTitle }>
      <Paper>
        <Box className={ classes.orderTitleContent }>
          <Typography
            variant="h4"
            component="h4"
          >
            { orderTitle }
          </Typography>
          <Box display="flex">
            <Typography
              variant="h5"
              component="h5"
              style={{ marginRight: theme.spacing(1) }}
            >
              { customerName }
            </Typography>
            <Typography
              variant="h5"
              component="h5"
              style={{ fontWeight: 300 }}
            >
              { avatar }
            </Typography>
          </Box>
          <Box display="flex">
            <Typography
              variant="h5"
              component="h5"
            >
              { DateUtils.getDisplayDate(date) }
            </Typography>
            <Box ml={ 2 }>
              <Typography
                variant="h5"
                component="h5"
                color="error"
              >
                { progress }
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default OrderTitle;