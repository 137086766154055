import * as React from "react";
import { AppBar, Box, Drawer, Toolbar, Typography } from "@material-ui/core";
import useEditorLayoutStyles from "styles/layouts/app-layout";
import logo from "../../resources/img/casemet-logo.png";
import strings from "localization/strings";
/**
 * Component properties
 */
interface Props {
  drawerContent: React.ReactNode;
  editorContent: React.ReactNode;
}

/**
 * Application layout component
 *
 * @param props component properties
 */
const AppLayout: React.VoidFunctionComponent<Props> = ({ drawerContent, editorContent }) => {
  const classes = useEditorLayoutStyles();

  /**
   * Component render
   */
  return (
    <Box className={ classes.root }>
      <AppBar style={{ zIndex: 1201 }}>
        <Toolbar>
          <Box className={ classes.logoContainer }>
            <img alt="Logo" className={ classes.logo } src={ logo }/>
          </Box>
          <Box ml={ 4 }>
            <Typography variant="h5" color="textSecondary">
              { strings.appTitle }
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={ classes.drawer }
        classes={{ paper: classes.drawerPaper }}
      >
        <Toolbar/>
        { drawerContent }
      </Drawer>
      <main className={ classes.content }>
        <Toolbar/>
        { editorContent }
      </main>
    </Box>
  );
};

export default AppLayout;