/* tslint:disable */
/* eslint-disable */
/**
 * Casemet API spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Customer object
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    lemonId: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    classification: CustomerClassificationEnum;
}

/**
* @export
* @enum {string}
*/
export enum CustomerClassificationEnum {
    A = 'A',
    B = 'B',
    C = 'C'
}

export function CustomerFromJSON(json: any): Customer {
    return CustomerFromJSONTyped(json, false);
}

export function CustomerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Customer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'lemonId': json['lemonId'],
        'name': json['name'],
        'classification': json['classification'],
    };
}

export function CustomerToJSON(value?: Customer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'lemonId': value.lemonId,
        'name': value.name,
        'classification': value.classification,
    };
}


