import * as React from "react";
import { Divider } from "@material-ui/core";
import useHorizontalDividerStyles from "styles/generic/horizontal-divider";

/**
 * Horizontal divider component
 */
const HorizontalDivider: React.FC = () => {
  const classes = useHorizontalDividerStyles();

  return (
    <Divider className={ classes.root }/>
  );
};

export default HorizontalDivider;