import * as React from "react";
import { Box, Grid, List, MenuItem, Paper, TextField, Typography, CircularProgress } from "@material-ui/core";
import { useAppSelector } from "app/hooks";
import AdvancedListItem from "components/generic/advanced-list-item";
import { selectCustomers } from "features/customer-slice";
import { selectSelectedOrder } from "features/order-slice";
import strings from "localization/strings";
import useEditorContentStyles from "styles/editor-content/editor-content";
import theme from "theme/theme";
import { ShowOrderOpts, SortByOpts } from "types";
import HorizontalDivider from "../generic/horizontal-divider";
import Api from "api";
import { selectAuth } from "features/auth-slice";
import { Material, Product, WorkItem } from "generated/client";
import { useContext } from "react";
import { ErrorContext } from "components/generic/error-handler";
import OrderTitle from "./order-title";
import { selectSelectedProduct } from "features/product-slice";
import DateUtils from "utils/date";
import WarningIcon from "@material-ui/icons/Warning";

/**
 * Editor content component
 */
const EditorContent: React.FC = () => {
  const classes = useEditorContentStyles();
  const selectedOrder = useAppSelector(selectSelectedOrder);
  const selectedProduct = useAppSelector(selectSelectedProduct);
  const customers = useAppSelector(selectCustomers);
  const { accessToken } = useAppSelector(selectAuth);
  const context = useContext(ErrorContext);

  /**
   * Component states
   */
  const [ products, setProducts ] = React.useState<Product[]>([]);
  const [ workItems, setWorkItems ] = React.useState<WorkItem[]>([]);
  const [ materials, setMaterials ] = React.useState<Material[]>([]);
  const [ loading, setLoading ] = React.useState(true);

  /**
   * Loads order data
   */
  const loadOrderData = async () => {
    if (!accessToken || !selectedOrder) {
      return;
    }

    setLoading(true);

    try {
      const [ productList, workItemList ] = await Promise.all([
        Api.getProductsApi(accessToken).listProducts({ orderId: selectedOrder.id }),
        Api.getWorkItemsApi(accessToken).listWorkItems({ orderId: selectedOrder.id })
      ]);

      const materialList = await Promise.all(productList.map(async (product): Promise<Material[]> => {
        return Api.getMaterialsApi(accessToken).listMaterials({ productId: product.id });
      }));

      setProducts(productList);
      setWorkItems(workItemList);
      setMaterials(materialList.flat());
    } catch (error) {
      context.setError(strings.errorHandling.title, error);
    }

    setLoading(false);
  };

  /**
   * Effect for loading order data when selected order is changed to redux
   */
  React.useEffect(() => {
    loadOrderData();
  }, [ selectedOrder ]);

  /**
   * Renders order list
   */
  const renderOrderTitle = () => {
    if (!selectedOrder || !selectedOrder.id) {
      return null;
    }
  
    const orderCustomer = customers.find(customer => customer.id === selectedOrder.customerId);
  
    if (!orderCustomer) {
      return null;
    }

    return (
      <OrderTitle
        orderTitle={ `${strings.order.title}: ${selectedOrder.lemonId}` }
        customerName={ orderCustomer.name || "" }
        date={ selectedOrder.deliveryDate }
        avatar={ orderCustomer.classification || "" }
        progress={ `${strings.order.daysLate}: ${selectedOrder.daysLate}` }
      />
    );
  };

  /**
   * Render list filer
   *
   * @param title title of the filter
   */
  const renderListFilter = (title: string) => {
    // TODO controlled select

    return (
      <>
        <Box className={ classes.listFilter }>
          <Typography variant="h5">
            { title }
          </Typography>
          <Box mb={ 2 } className={ classes.listFilterGroup }>
            <Box mr={ 1 }>
              <TextField
                select
                label={ strings.filter.show.title }
              >
                <MenuItem value={ ShowOrderOpts.ALL_ORDERS }>{ strings.filter.show.allOrders }</MenuItem>
                <MenuItem value={ ShowOrderOpts.RISKS }>{ strings.filter.show.risks }</MenuItem>
              </TextField>
            </Box>
            <Box ml={ 1 }>
              <TextField
                select
                label={ strings.filter.sortBy.title }
              >
                <MenuItem value={ SortByOpts.LATEST }>{ strings.filter.sortBy.latest }</MenuItem>
              </TextField>
            </Box>
          </Box>
        </Box>
        <HorizontalDivider/>
      </>
    );
  };

  /**
   * Renders product rows
   *
   * @param productList product list
   */
  const renderProductRows = (productList: Product[]) => {
    return productList.map(parent => {
      const childProducts = products.filter(product => product.parentId === parent.id);

      return (
        <AdvancedListItem
          key={ parent.id }
          title={ parent.code }
          subtitle={ parent.name }
          additionalContent={
            <Box mt={ 1 } display="flex">
              <Typography style={{ marginRight: theme.spacing(1) }}>
                { `${strings.generic.amount}: ${parent.amount}` }
              </Typography>
              <Typography>
                { `${strings.generic.readyAmount}: ${parent.readyAmount}` }
              </Typography>
            </Box>
          }
        >
          { childProducts.length && renderProductRows(childProducts) }
        </AdvancedListItem>
      );
    });
  };

  /**
   * Render product List
   */
  const renderProducts = () => {
    const rootProducts = products.filter(product => product.id === selectedProduct?.id);

    return (
      <Grid item xs style={{ marginRight: theme.spacing(1) }}>
        { renderListFilter(strings.editorContent.product) }
        <Box className={ classes.list }>
          <Paper>
            <Box p="0 8px">
              <List>
                { renderProductRows(rootProducts) }
              </List>
            </Box>
          </Paper>
        </Box>
      </Grid>
    );
  };

  /**
   * Renders work item info
   *
   * @param workItem work item
   */
  const renderWorkItemInfo = (workItem: WorkItem) => {
    return (
      <AdvancedListItem
        key={ `WorkItemInfo-${workItem.id}` }
        disableClick
        title={ `${strings.generic.specification}: ${workItem.description}` }
        additionalContent={
          <Box mt={ 1 } display="flex">
            <Typography style={{ marginRight: theme.spacing(1) }}>
              { `${strings.generic.startDate}: ${DateUtils.getDisplayDateTime(workItem.startDate)}` }
            </Typography>
            <Typography>
              { `${strings.generic.endDate}: ${DateUtils.getDisplayDateTime(workItem.endDate)}` }
            </Typography>
          </Box>
        }
      />
    );
  };

  /**
   * Renders single work item
   *
   * @param workItem work item
   */
  const renderSingleWorkItem = (workItem: WorkItem) => (
    <AdvancedListItem
      key={ `SingleWorkItem-${workItem.id}` }
      title={ workItem.name }
      additionalContent={
        <Box mt={ 1 } display="flex">
          <Typography style={{ marginRight: theme.spacing(1) }}>
            { `${strings.generic.amount}: ${workItem.amount}` }
          </Typography>
          <Typography>
            { `${strings.generic.readyAmount}: ${workItem.readyAmount}` }
          </Typography>
        </Box>
      }
    >
      { renderWorkItemInfo(workItem) }
    </AdvancedListItem>
  );

  /**
   * Renders single material
   *
   * @param material material
   */
  const renderSingleMaterial = (material: Material) => (
    <AdvancedListItem
      key={ `SingleMaterial-${material.id}` }
      title={ material.name }
      additionalContent={
        <Box mt={ 1 } display="flex" justifyContent="space-between">
          <Typography style={{ marginRight: theme.spacing(1) }}>
            { `${strings.material.requiredAmount}: ${material.requiredAmount}` }
          </Typography>
          <Typography>
            { `${strings.material.storageAmount}: ${material.storageAmount}` }
          </Typography>
          <Box>
            { material.hasRisk && <WarningIcon color="error"/> }
          </Box>
        </Box>
      }
    />
  );

  /**
   * Renders product rows for work items and materials
   *
   * @param renderMaterials is current render for materials
   */
  const renderProductRowsForWorkItemsAndMaterials = (renderMaterials: boolean) => {
    return products.map(product => (
      <AdvancedListItem
        key={ product.id }
        title={ product.code }
        subtitle={ !renderMaterials ? product.name : undefined }
      >
        { !renderMaterials ?
          workItems.filter(item => item.productId === product.id).map(renderSingleWorkItem) :
          materials.filter(material => material.productId === product.id).map(renderSingleMaterial)
        }
      </AdvancedListItem>
    ));
  };

  /**
   * Render work items
   */
  const renderWorkItems = () => {
    return (
      <Grid item xs style={{ margin: `0 ${theme.spacing(1)}px` }}>
        { renderListFilter(strings.editorContent.work) }
        <Box className={ classes.list }>
          <Paper>
            <Box p="0 8px">
              <List>
                { renderProductRowsForWorkItemsAndMaterials(false) }
              </List>
            </Box>
          </Paper>
        </Box>
      </Grid>
    );
  };

  /**
   * Render material List
   */
  const renderMaterials = () => {
    return (
      <Grid item xs style={{ marginLeft: theme.spacing(1) }}>
        { renderListFilter(strings.editorContent.material) }
        <Box className={ classes.list }>
          <Paper>
            <Box p="0 8px">
              <List>
                { renderProductRowsForWorkItemsAndMaterials(true) }
              </List>
            </Box>
          </Paper>
        </Box>
      </Grid>
    );
  };

  if (!selectedOrder) {
    return (
      <Box display="flex" justifyContent="center">
        <Typography variant="h3">
          { strings.order.selectOrder }
        </Typography>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <Typography variant="h3">
          <CircularProgress color="secondary" size={ 100 }/>
        </Typography>
      </Box>
    );
  }

  /**
   * Component render
   */
  return (
    <>
      { renderOrderTitle() }
      <Grid
        container
        spacing={ 3 }
      >
        { renderProducts() }
        { renderWorkItems() }
        { renderMaterials() }
      </Grid>
    </>
  );
};

export default EditorContent;