/* tslint:disable */
/* eslint-disable */
/**
 * Casemet API spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * order row object
 * @export
 * @interface OrderRow
 */
export interface OrderRow {
    /**
     * 
     * @type {string}
     * @memberof OrderRow
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRow
     */
    orderId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRow
     */
    productId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRow
     */
    lemonId: string;
    /**
     * 
     * @type {Date}
     * @memberof OrderRow
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderRow
     */
    endDate: Date;
    /**
     * 
     * @type {number}
     * @memberof OrderRow
     */
    requiredAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderRow
     */
    deliveredAmount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderRow
     */
    hasRisk: boolean;
}

export function OrderRowFromJSON(json: any): OrderRow {
    return OrderRowFromJSONTyped(json, false);
}

export function OrderRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderRow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'orderId': json['orderId'],
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'lemonId': json['lemonId'],
        'startDate': (new Date(json['startDate'])),
        'endDate': (new Date(json['endDate'])),
        'requiredAmount': !exists(json, 'requiredAmount') ? undefined : json['requiredAmount'],
        'deliveredAmount': !exists(json, 'deliveredAmount') ? undefined : json['deliveredAmount'],
        'hasRisk': json['hasRisk'],
    };
}

export function OrderRowToJSON(value?: OrderRow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'orderId': value.orderId,
        'productId': value.productId,
        'lemonId': value.lemonId,
        'startDate': (value.startDate.toISOString()),
        'endDate': (value.endDate.toISOString()),
        'requiredAmount': value.requiredAmount,
        'deliveredAmount': value.deliveredAmount,
        'hasRisk': value.hasRisk,
    };
}


