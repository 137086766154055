import { OrderRow, Product } from "generated/client";

/**
 * Access token
 */
export interface AccessToken {
  created: Date;
  access_token: string;
  expires_in?: number;
  refresh_token?: string;
  refresh_expires_in?: number;
  firstName?: string;
  lastName?: string;
  userId?: string;
  roles?: string[];
}

/**
 * Configuration
 */
export interface Configuration {
  auth: {
    url: string;
    realm: string;
    clientId: string;
  };
  api: {
    baseUrl: string;
  };
}

/**
 * Show order option type
 */
export type ShowOrderOpt = "ALL_ORDERS" | "RISKS";

/**
 * Show order options
 */
export enum ShowOrderOpts {
  ALL_ORDERS = "ALL_ORDERS",
  RISKS = "RISKS"
}

/**
 * Customer categories option type
 */
export type CustomerCategoryOpt = "ALL_CATEGORIES";

/**
 * Customer categories options
 */
export enum CustomerCategoryOpts {
  ALL_CATEGORIES = "ALL_CATEGORIES"
}

/**
 * Sort by option type
 */
export type SortByOpt = "LATEST";

/**
 * Customer categories options
 */
export enum SortByOpts {
  LATEST = "LATEST"
}

/**
 * Interface for error context type
 */
export interface ErrorContextType {
  error?: string;
  setError: (message: string, error?: any) => void;
}

/**
 * Interface for storing order related data for order-list component
 */
export interface OrderData {
  orderRows: OrderRow[];
  products: Product[]
}