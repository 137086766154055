/* tslint:disable */
/* eslint-disable */
/**
 * Casemet API spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Product object
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    orderId: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    lemonId: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    readyAmount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    hasRisk: boolean;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    daysLate?: number;
}

export function ProductFromJSON(json: any): Product {
    return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): Product {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'orderId': json['orderId'],
        'lemonId': json['lemonId'],
        'code': json['code'],
        'name': json['name'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'readyAmount': !exists(json, 'readyAmount') ? undefined : json['readyAmount'],
        'hasRisk': json['hasRisk'],
        'daysLate': !exists(json, 'daysLate') ? undefined : json['daysLate'],
    };
}

export function ProductToJSON(value?: Product | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'parentId': value.parentId,
        'orderId': value.orderId,
        'lemonId': value.lemonId,
        'code': value.code,
        'name': value.name,
        'amount': value.amount,
        'readyAmount': value.readyAmount,
        'hasRisk': value.hasRisk,
        'daysLate': value.daysLate,
    };
}


