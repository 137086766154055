import { createTheme } from "@material-ui/core";

const { breakpoints } = createTheme();

export default createTheme({

  palette: {
    primary: {
      main: "#BCCF00",
      light: "#3E55BD",
      dark: "#F5F5F5"
    },
    secondary: {
      main: "#BCCF00"
    },
    text: {
      primary: "#333333",
      secondary: "#ffffff"
    },
    warning: {
      main: "#EB5757"
    },
    background: {
      default: "#ffffff"
    }
  },

  typography: {
    allVariants: {
      fontFamily: "Exo, sans-serif",
      fontWeight: 500
    },
    h1: {
      fontFamily: "Exo, sans-serif",
      fontWeight: 900,
      fontSize: 48,
      [breakpoints.down("sm")]: {
        fontSize: "1.75rem"
      }
    },
    h2: {
      fontFamily: "Exo, sans-serif",
      fontWeight: 800,
      fontSize: 36
    },
    h3: {
      fontSize: 26
    },
    h4: {
      fontSize: 20
    },
    h5: {
      fontSize: 16
    },
    h6: {
      fontSize: 12
    },
    body1: {
      fontSize: 14
    },
    body2: {
      fontSize: 14
    }
  },

  props: {
    MuiAppBar: {
      elevation: 0,
      position: "fixed",
      color: "default"
    },
    MuiTextField: {
      variant: "standard",
      size: "small"
    },
    MuiPaper: {
      square: true
    },
    MuiSelect: {
      variant: "standard"
    }
  },

  overrides: {
    MuiCssBaseline: {
      "@global": {
        a: {
          textDecoration: "none"
        }
      }
    },
    MuiSelect: {
      select: {
        minWidth: 40
      }
    },
    MuiInputLabel: {
      root: {
        fontSize: 12
      },
      shrink: {
        transform: "translate(0, 1.5px) scale(0.90)"
      },
      marginDense: {
        transform: "translate(0, 20px) scale(1)"
      }
    },
    MuiInputBase: {
      input: {
        padding: "8px 0 0",
        fontSize: 14
      }
    },
    MuiFormLabel: {
      root: {
        color: "#444",
        "&$focused": {
          color: "#444"
        }
      },
      focused: {}
    },
    MuiMenuItem: {
      root: {

      }
    },
    MuiInput: {
      underline: {
        "&:before": {
          display: "none"
        },
        "&:after": {
          display: "none"
        }
      }
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: "#000"
      },
      root: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
      }
    },
    MuiAccordion: {
      root: {
        boxShadow: "none",
        "&:not(:last-child)": {
          borderBottom: 0
        },
        "&$expanded": {
          margin: "auto"
        },
        "&.child": {
          border: "none"
        }
      },
      expanded: {}
    },
    MuiAccordionSummary: {
      root: {
        flexDirection: "row-reverse",
        marginBottom: -1,
        minHeight: 56
      },
      content: {
        margin: "16px 0",
        "&$expanded": {
          margin: "16px 0"
        }
      },
      expandIcon: {
        "&$expanded": {
          transform: "rotate(90deg)"
        }
      },
      expanded: {}
    },
    MuiAccordionDetails: {
      root: {
        padding: 0
      }
    },
    MuiAvatar: {
      root: {
        height: 30,
        width: 30
      }
    },
    MuiIconButton: {
      edgeEnd: {
        marginLeft: -12,
        marginRight: "initial"
      }
    },
    MuiToolbar: {
      root: {
        alignItems: "center",
        justifyContent: "space-between"
      }
    },
    MuiButton: {
      root: {
        borderRadius: 0,
        fontWeight: 500,
        [breakpoints.down(460)]: {
          height: 46,
          width: "100%"
        }
      },
      text: {
        textTransform: "initial"
      },
      outlined: {
        textTransform: "initial"
      },
      containedPrimary: {
        textTransform: "initial"
      },
      outlinedPrimary: {
        textTransform: "initial"
      },
      textPrimary: {
        textTransform: "initial"
      },
      containedSecondary: {
        textTransform: "initial"
      }
    },
    MuiDialog: {
      paper: {
        backgroundColor: "#fff"
      }
    },
    MuiOutlinedInput: {
      root: {

      }
    },
    MuiList: {
      root: {
        width: "100%"
      }
    },
    MuiListItemText: {
      secondary: {
        color: "#989898"
      }
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#EEE"
        }
      },
      selected: {}
    },
    MuiPopover: {
      paper: {
        backgroundColor: "#fff"
      }
    }
  }
});