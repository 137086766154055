/* tslint:disable */
/* eslint-disable */
/**
 * Casemet API spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Product,
    ProductFromJSON,
    ProductToJSON,
} from '../models';

export interface FindProductRequest {
    productId: string;
}

export interface ListProductsRequest {
    parentId?: string;
    orderId?: string;
    hasRiskOnly?: boolean;
    sortBy?: ListProductsSortByEnum;
}

/**
 * 
 */
export class ProductsApi extends runtime.BaseAPI {

    /**
     * Finds product details by product id
     * Finds a product
     */
    async findProductRaw(requestParameters: FindProductRequest): Promise<runtime.ApiResponse<Product>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling findProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/products/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
    }

    /**
     * Finds product details by product id
     * Finds a product
     */
    async findProduct(requestParameters: FindProductRequest): Promise<Product> {
        const response = await this.findProductRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists products
     * Lists products
     */
    async listProductsRaw(requestParameters: ListProductsRequest): Promise<runtime.ApiResponse<Array<Product>>> {
        const queryParameters: any = {};

        if (requestParameters.parentId !== undefined) {
            queryParameters['parentId'] = requestParameters.parentId;
        }

        if (requestParameters.orderId !== undefined) {
            queryParameters['orderId'] = requestParameters.orderId;
        }

        if (requestParameters.hasRiskOnly !== undefined) {
            queryParameters['hasRiskOnly'] = requestParameters.hasRiskOnly;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductFromJSON));
    }

    /**
     * Lists products
     * Lists products
     */
    async listProducts(requestParameters: ListProductsRequest): Promise<Array<Product>> {
        const response = await this.listProductsRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ListProductsSortByEnum {
    DaysLate = 'DAYS_LATE',
    Name = 'NAME'
}
