import LocalizedStrings, { LocalizedStringsMethods } from "localized-strings";
import en from "./en.json";
import fi from "./fi.json";

/**
 * Interface describing localized strings
 */
export interface IStrings extends LocalizedStringsMethods {

  /**
   * App title
   */
  appTitle: string;

  /**
   * Translations related to generic words
   */
  generic: {
    add: string;
    cancel: string;
    confirm: string;
    copy: string;
    save: string;
    delete: string;
    search: string;
    language: string;
    select: string;
    amount: string;
    readyAmount: string;
    loadMore: string;
    startDate: string;
    endDate: string;
    specification: string;
    onSchedule: string;
    late: string;
  };

  /**
   * Localizations related to filter
   */
  filter: {
    title: string;
    show: {
      title: string;
      allOrders: string;
      risks: string;
    };
    dateStart: string;
    dateEnd: string;
    customerCategory: {
      title: string;
      allCategory: string;
    };
    sortBy: {
      title: string;
      latest: string;
    };
  };

  /**
   * Localization related to editor content
   */
  editorContent: {
    product: string;
    work: string;
    material: string;
  };

  /**
   * Localizations related to orders
   */
  order: {
    selectOrder: string;
    title: string;
    daysLate: string;
  };

  /**
   * Localization related to materials
   */
  material: {
    requiredAmount: string;
    storageAmount: string;
  };

  /**
   * Translations related to error handling
   */
  errorHandling: {
    title: string;
    store: string;
    orders: {
      list: string;
    };
    orderRows: {
      list: string;
    };
  };

}

const strings: IStrings = new LocalizedStrings({ en: en, fi: fi });

export default strings;