import { makeStyles } from "@material-ui/core";

const useOrderFilterStyles = makeStyles(theme => ({
  
  root: {
    margin: theme.spacing(2),
    marginBottom: 0
  },

  title: {
    height: 86,
    paddingTop: theme.spacing(2),
    display: "flex",
    alignItems: "center"
  },

  filterRow: {
    height: 70,
    display: "flex",
    alignItems: "center"
  },

  filterItemLeft: {
    width: "50%",
    display: "grid",
    justifySelf: "left",
    justifyContent: "left",
    paddingRight: theme.spacing(2)
  },

  filterItemRight: {
    width: "50%",
    display: "grid",
    justifySelf: "right",
    justifyContent: "left",
    paddingLeft: theme.spacing(2)
  },

  verticalDivider: {
    height: "75%"
  },

  datePicker: {
    "& div div button.MuiButtonBase-root": {
      color: theme.palette.primary.main
    }
  }

}), {
  name: "order-filter"
});

export default useOrderFilterStyles;