/* tslint:disable */
/* eslint-disable */
/**
 * Casemet API spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Material object
 * @export
 * @interface Material
 */
export interface Material {
    /**
     * 
     * @type {string}
     * @memberof Material
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Material
     */
    lemonId: string;
    /**
     * 
     * @type {string}
     * @memberof Material
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Material
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof Material
     */
    unit: string;
    /**
     * 
     * @type {number}
     * @memberof Material
     */
    storageAmount: number;
    /**
     * 
     * @type {number}
     * @memberof Material
     */
    requiredAmount: number;
    /**
     * 
     * @type {boolean}
     * @memberof Material
     */
    hasRisk: boolean;
}

export function MaterialFromJSON(json: any): Material {
    return MaterialFromJSONTyped(json, false);
}

export function MaterialFromJSONTyped(json: any, ignoreDiscriminator: boolean): Material {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'lemonId': json['lemonId'],
        'name': json['name'],
        'productId': json['productId'],
        'unit': json['unit'],
        'storageAmount': json['storageAmount'],
        'requiredAmount': json['requiredAmount'],
        'hasRisk': json['hasRisk'],
    };
}

export function MaterialToJSON(value?: Material | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'lemonId': value.lemonId,
        'name': value.name,
        'productId': value.productId,
        'unit': value.unit,
        'storageAmount': value.storageAmount,
        'requiredAmount': value.requiredAmount,
        'hasRisk': value.hasRisk,
    };
}


