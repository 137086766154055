import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "app/store";
import { Order } from "generated/client";

/**
 * Interface for customer state
 */
interface OrdersState {
  orders: Order[];
  selectedOrder?: Order;
}

/**
 * Initial orders state
 */
const initialState: OrdersState = {
  orders: []
};

/**
 * Orders slice of Redux store
 */
export const orderSlice = createSlice({
  name: "orders",
  initialState: initialState,
  reducers: {
    setOrders: (state, { payload }: PayloadAction<Order[]>) => {
      state.orders = payload;
    },
    setSelectedOrder: (state, { payload }: PayloadAction<Order>) => {
      state.selectedOrder = payload;
    }
  }
});

/**
 * Order actions from created order slice
 */
export const { setOrders, setSelectedOrder } = orderSlice.actions;

/**
 * Select order selector
 *
 * @param state Redux store root state
 * @returns order from Redux store
 */
export const selectOrders = (state: RootState) => state.order.orders;

/**
 * Select selected order selector
 *
 * @param state Redux store root state
 * @returns selected order from Redux store
 */
export const selectSelectedOrder = (state: RootState) => state.order.selectedOrder;

/**
 * Reducer for order slice
 */
export default orderSlice.reducer;