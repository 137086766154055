import * as React from "react";
import ReactDOM from "react-dom";
import App from "components/App";
import reportWebVitals from "reportWebVitals";
import { Provider } from "react-redux";
import { store } from "app/store";
import { CssBaseline, responsiveFontSizes, ThemeProvider } from "@material-ui/core";
import theme from "theme/theme";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={ store }>
      <ThemeProvider theme={ responsiveFontSizes(theme) }>
        <CssBaseline/>
        <App/>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();