import moment from "moment";

/**
 * Utility class for dates
 */
export default class DateUtils {

  /**
   * Formats given date to display date string
   *
   * @param date date to format
   * @returns returns formatted date string
   */
  public static getDisplayDate = (date?: Date) => {
    if (!date) {
      return "No date";
    }

    return moment(date).format("DD.MM.YYYY");
  };

  /**
   * Formats given date to display date-time string
   *
   * @param date date to format
   * @returns returns formatted date-time string
   */
  public static getDisplayDateTime = (date?: Date) => {
    if (!date) {
      return "No date";
    }

    return moment(date).format("DD.MM.YYYY hh:mm");
  };

}