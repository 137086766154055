import * as React from "react";
import EditorLayout from "../../layouts/app-layout";
import useMainScreenStyles from "styles/screens/main-screen";
import { Box, CircularProgress, Fade } from "@material-ui/core";
import OrderList from "../../order-list/order-list";
import OrderFilter from "components/order-filter/order-filter";
import EditorContent from "components/editor-content/editor-content";

/** Minimum time that loader is visible */
const MIN_LOADING_TIME = 1000;

/**
 * Main screen component
 */
const MainScreen: React.FC = () => {
  const classes = useMainScreenStyles();
  const [ loading, setLoading ] = React.useState(true);

  /**
   * Lazily removes loader
   *
   * @param startTime loading start time
   */
  const lazyRemoveLoader = (startTime: number) => {
    const loadingEnd = new Date().valueOf();
    const difference = loadingEnd - startTime;
    difference < MIN_LOADING_TIME ?
      setTimeout(() => setLoading(false), MIN_LOADING_TIME - difference) :
      setLoading(false);
  };

  /**
   * Effect for loading start
   */
  React.useEffect(() => {
    (async () => {
      const loadingStart = new Date().valueOf();
      lazyRemoveLoader(loadingStart);
    })();
  }, []);

  /**
   * Render drawer content
   */
  const renderDrawer = () => {
    return (
      <>
        <OrderFilter/>
        <OrderList/>
      </>
    );
  };

  /**
   * Render editor content
   */
  const renderEditorContent = () => {
    return (
      <Box p={ 4 }>
        <EditorContent/>
      </Box>
    );
  };

  /**
   * Component render
   */
  return (
    <>
      <Fade
        appear={ false }
        in={ loading }
      >
        <Box className={ classes.loaderContainer }>
          <CircularProgress size={ 100 } color="secondary"/>
        </Box>
      </Fade>
      <EditorLayout
        drawerContent={ renderDrawer() }
        editorContent={ renderEditorContent() }
      />
    </>
  );
};

export default MainScreen;